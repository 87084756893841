
import { Component, Vue } from 'vue-property-decorator';
import { readConsumers } from '@/store/consumers/getters';
import { dispatchGetConsumers } from '@/store/consumers/actions';
import ConsumerStats from './ConsumerStats.vue';
import ExportConsumers from './ExportConsumers.vue';
import ExportConsumerStats from './ExportConsumerStats.vue';

const PAGE_SIZE = 100;

@Component({
  components: {
    ConsumerStats,
    ExportConsumers,
    ExportConsumerStats,
  },
})
export default class Consumers extends Vue {
  private headers = [
    {
      text: 'Name',
      sortable: true,
      value: 'name',
      align: 'left',
    },
    {
      text: 'Registration Date',
      sortable: true,
      value: 'registration_date',
      align: 'center',
    },
    {
      text: 'Is Active',
      sortable: false,
      value: 'is_active',
      align: 'center',
    },
    {
      text: 'Actions',
      sortable: false,
      value: 'id',
      align: 'center',
    },
  ];

  private appId = 0;
  private tableHeight = 0;
  private entries = true;
  private stats = false;
  private sortBy = this.headers[0].value;
  private sortDesc = false;
  private scrollPosition = 0;
  private busy = false;

  private beforeMount() {
    this.appId = Number(this.$router.currentRoute.params.id);
    this.updateList();
  }

  private mounted() {
    const el = document.getElementById('dtable');
    el?.addEventListener('wheel', this.handleScroll);
  }

  private beforeRouteUpdate(to, from, next) {
    next();
    this.updateList();
  }

  private clear() {
    this.sortBy = this.headers[0].value;
    this.sortDesc = false;
    this.scrollPosition = 0;
    this.busy = false;
  }

  private get consumers() {
    return readConsumers(this.$store);
  }

  private updateList() {
    this.clear();
    this.getLists();
  }

  private async getLists() {
    dispatchGetConsumers(this.$store, {
      appId: this.appId,
      skip: 0,
      limit: PAGE_SIZE,
      sort: this.sortBy,
      desc: this.sortDesc,
    });
  }

  private handleResize() {
    this.tableHeight =
      window.innerHeight -
      /* header */ 64 -
      /* title */ 64 -
      /* tabs */ 48 -
      /* buttons */ 88 -
      /* footer*/ 48;
  }

  private async handleScroll() {
    const table = document.querySelector('div.v-data-table__wrapper')!;
    const pos = table.clientHeight + table.scrollTop;
    if (
      !this.busy &&
      pos !== this.scrollPosition &&
      pos >= table.scrollHeight - 50
    ) {
      this.busy = true;
      await dispatchGetConsumers(this.$store, {
        appId: this.appId,
        skip: this.consumers.length,
        limit: PAGE_SIZE,
        sort: this.sortBy,
        desc: this.sortDesc,
      });
      this.busy = false;
    }
    this.scrollPosition = pos;
  }

  private showExport() {
    if (this.entries)
      (this.$refs.exportConsumersDialog as ExportConsumers).show();
    else (this.$refs.exportConsumerStatsDialog as ExportConsumerStats).show();
  }

  private handleOptionsUpdate({ sortBy, sortDesc }) {
    this.sortBy = sortBy;
    this.sortDesc = sortDesc;
    this.getLists();
  }
}
