
import moment from 'moment';
import Vue from 'vue';
import Component from 'vue-class-component';
import { api } from '@/api';
import { mainModule } from '@/store/main';

@Component
export default class ExportConsumerStats extends Vue {
  private dialog = false;
  private filename = '';
  private valid = false;
  private rules = {
    required: (value) => value.trim().length > 0 || 'Required',
  };

  private get defaultFilename() {
    return 'consumer_stats_' + moment().format('YYYYMMDD-HHmm');
  }

  public show() {
    this.dialog = true;
    this.filename = this.defaultFilename;
  }

  private async doExport() {
    const file = await api.exportConsumerStats(
      mainModule.state.token,
      Number(this.$router.currentRoute.params.id),
    );
    this.download('data:text/csv;charset=utf-8,' + file.data);
    this.dialog = false;
  }

  private download(content) {
    const data = encodeURI(content);
    const link = document.createElement('a');
    link.href = data;
    link.download = this.filename + '.csv';
    link.click();
  }
}
