
import Vue from 'vue';
import Component from 'vue-class-component';
import VChart from 'vue-echarts';
import { Prop, Watch } from 'vue-property-decorator';
import { dispatchGetConsumerStats } from '@/store/consumers/actions';
import { readConsumerStats } from '@/store/consumers/getters';

@Component({
  components: {
    VChart,
  },
})
export default class Stats extends Vue {
  private options = {
    xAxis: {
      data: [''],
    },
    yAxis: {},
    legend: {
      bottom: 0,
    },
    series: [
      {
        name: 'Registration',
        type: 'bar',
        stack: 'line',
        label: {
          show: true,
          position: 'inside',
        },
        data: [0],
        color: '#8601AF',
      },
    ],
    animation: false,
  };

  private async mounted() {
    this.update();
  }

  private get stats() {
    return readConsumerStats(this.$store);
  }

  private async update() {
    await dispatchGetConsumerStats(this.$store, {
      appId: Number(this.$router.currentRoute.params.id),
    });
    this.redraw();
  }

  private redraw() {
    this.options.xAxis.data = this.stats.map((x) => x.month);
    this.options.series[0].data = this.stats.map((x) => x.registration);
  }
}
